var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { LAYER_NAVIGATION } from './layer-navigation.constants';
import { getFocusableElements, initSubLevelTracking, onClearTimeOut, removeOverLay, setTabIndex, toggleOverLay } from '../../common/common.tools';
import { COMMONS } from '../../common/common.constants';
import { LayerPrimaryNavigation } from '../../common/layer-primary-navigation.class';
import { ACCESSIBILITY } from '../accessibility/accessibility-navigation.constants';
export default class UniverseContentLayer extends LayerPrimaryNavigation {
  constructor(primaryElement, accessibilityNavigationModule) {
    super(LAYER_NAVIGATION.CACHE.NAME, LAYER_NAVIGATION.CACHE.VALIDITY, primaryElement);
    this.accessibilityNavigationModule = accessibilityNavigationModule;
    this.hasChildElementOpened = false;
    this.bodyElement = document.querySelector('body');
    this.triggerNavigationButton = document.querySelector(`.${COMMONS.TRIGGER_NAVIGATION_BUTTON}`);
    this.universesListContainer = document.querySelector(`.${COMMONS.UNIVERSES_LIST}`);
  }
  init() {
    this.initUniverseItems();
    this.initPrimaryMenuItemEvent();
    this.initDisableLinks();
    this.initOnOpenNavigation();
    this.initOnCloseNavigation();
  }
  initPrimaryMenuItemEvent() {
    if (!this.primaryElement) return;
    const {
      mainLevel
    } = this.primaryElement.dataset;
    this.primaryElement.addEventListener('mouseleave', () => {
      if (!this.hasChildElementOpened) {
        if (mainLevel) this.mainLevelLabel = mainLevel;
        this.firstItemLeaveTimer = setTimeout(() => {
          this.hideLayerOnLeavePrimaryMenu();
        }, 400);
      }
    });
  }
  initOnOpenNavigation() {
    var _a;
    (_a = this.triggerNavigationButton) === null || _a === void 0 ? void 0 : _a.addEventListener('change', event => {
      this.setAriaHiddenOnPriamryNav(false);
      const target = event.target;
      toggleOverLay();
      if (target.checked) {
        this.loadFirstItem();
        this.initOnKeyboardNavigationBackAndCloseEvent();
        this.bodyElement.classList.add(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
        this.updateAccessibilityOnOpenNavigation();
        this.triggerNavigationButton.focus();
      }
    });
    this.primaryElement.addEventListener('mouseenter', () => {
      this.setAriaHiddenOnPriamryNav(false);
      clearTimeout(this.firstItemLeaveTimer);
      this.loadFirstItem();
    });
  }
  setAriaHiddenOnPriamryNav(value) {
    var _a;
    (_a = this.universesListContainer) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-hidden', `${value}`);
  }
  loadFirstItem() {
    const universeItemToLoad = this.openedUniverse ? this.openedUniverse : this.universeItems[0];
    this.addSelectedElementCssClass(universeItemToLoad);
    this.prepareLayerAndGetUniverseItems(universeItemToLoad);
    universeItemToLoad === null || universeItemToLoad === void 0 ? void 0 : universeItemToLoad.focus();
  }
  initOnKeyboardNavigationBackAndCloseEvent() {
    document.removeEventListener('keyup', event => {
      this.onKeyboardNavigationBackAndCloseEnvent(event);
    });
    document.addEventListener('keyup', event => {
      this.onKeyboardNavigationBackAndCloseEnvent(event);
    });
  }
  onKeyboardNavigationBackAndCloseEnvent(event) {
    var _a;
    if (event.key === ACCESSIBILITY.AUTHORIZED_KEY.ESCAPE) {
      this.triggerNavigationButton.checked = false;
      this.hideLayerOnLeavePrimaryMenu();
      this.updateAccessibilityOnCloseNavigation();
      this.triggerNavigationButton.focus();
      removeOverLay();
      this.openedUniverse = null;
    }
    if (event.key === ACCESSIBILITY.AUTHORIZED_KEY.ARROW_LEFT) {
      const isTablet = this.primaryElement && this.primaryElement.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK}`) ? true : false;
      if (isTablet) return;
      this.initOnKeyboardNavigationBackAndCloseEvent();
      this.bodyElement.classList.add(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
      const currentElement = this.accessibilityNavigationModule.focusableElementOnLeftArrow && this.universeItems.indexOf(this.accessibilityNavigationModule.focusableElementOnLeftArrow) > -1 ? this.universeItems[this.universeItems.indexOf(this.accessibilityNavigationModule.focusableElementOnLeftArrow)] : this.universeItems[0];
      this.accessibilityNavigationModule.update(this.universeItems[0], currentElement, this.universeItems);
      (_a = this.accessibilityNavigationModule.focusableElementOnLeftArrow) === null || _a === void 0 ? void 0 : _a.focus();
    }
  }
  initOnCloseNavigation() {
    var _a;
    (_a = this.triggerNavigationButton) === null || _a === void 0 ? void 0 : _a.addEventListener('change', event => {
      var _a;
      if (!((_a = event.target) === null || _a === void 0 ? void 0 : _a.checked)) {
        this.hideLayerOnLeavePrimaryMenu();
        this.updateAccessibilityOnCloseNavigation();
      }
    });
  }
  hideLayerOnLeavePrimaryMenu() {
    this.setAriaHiddenOnPriamryNav(true);
    if (!this.primaryElement) return;
    const subNavigationContent = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTENT_SELECTOR}`);
    if (subNavigationContent) {
      subNavigationContent.innerHTML = '';
    }
    const subNavigationContainer = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTAINER_SELECTOR}`);
    subNavigationContainer.classList.remove(LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS, LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK);
    if (!this.triggerNavigationButton.checked) {
      this.bodyElement.classList.remove(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
    }
    setTabIndex(this.triggerNavigationButton, 0);
    const universeItemToBlur = this.openedUniverse ? this.openedUniverse : this.universeItems[0];
    universeItemToBlur === null || universeItemToBlur === void 0 ? void 0 : universeItemToBlur.blur();
    universeItemToBlur && this.removeSelectedElementCssClass(universeItemToBlur);
    this.openedUniverse = null;
  }
  initUniverseItems() {
    this.setAriaHiddenOnPriamryNav(true);
    if (!this.primaryElement) return;
    this.universeItems = [...this.primaryElement.querySelectorAll(`.${LAYER_NAVIGATION.ITEM_UNIVERSE_SELECTOR}`)];
    if (this.universeItems) {
      this.initUniverseEvents();
      const elementParentList = this.primaryElement.querySelector(`.${LAYER_NAVIGATION.LAYER_CONTAINERS_SELECTOR}`);
      initSubLevelTracking(elementParentList, this.mainLevelLabel);
    }
  }
  initUniverseEvents() {
    this.universeItems.forEach(item => {
      item.addEventListener('click', event => {
        var _a;
        this.hasChildElementOpened = true;
        if (!((_a = event.target) === null || _a === void 0 ? void 0 : _a.classList.contains(`${LAYER_NAVIGATION.ITEM_UNIVERSE_NO_CHILDREN}`))) {
          this.prepareLayerElements({
            container: LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK,
            loader: LAYER_NAVIGATION.LOADER_SHOW_CSS_ON_CLICK
          });
          const urlNavigation = this.getUrl(event.target, COMMONS.UNIVERSES_AJAX_URL, 'desktop');
          if (!urlNavigation) return;
          this.timer = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            yield this.getSubUniverseItemFromServer(urlNavigation, event.target);
          }), 200);
        }
      });
      this.delayMouseOver(item);
      item.addEventListener('focus', this.onGetSubUniverseItems.bind(this));
      item.addEventListener('blur', onClearTimeOut.bind(null, this.timer));
    });
  }
  delayMouseOver(element) {
    element.addEventListener('mouseenter', this.onDelayMouseEnter.bind(this));
    element.addEventListener('mouseleave', () => this.onDelayMouseLeave());
  }
  onDelayMouseEnter(event) {
    this.menuEnterTimer = setTimeout(() => {
      clearTimeout(this.menuLeaveTimer);
      this.onGetSubUniverseItems(event);
    }, 300);
  }
  onDelayMouseLeave() {
    clearTimeout(this.menuEnterTimer);
    this.menuLeaveTimer = setTimeout(() => {
      onClearTimeOut(this.timer);
    }, 300);
  }
  onGetSubUniverseItems(event) {
    return __awaiter(this, void 0, void 0, function* () {
      const target = event.target;
      this.initToggleSelectedElementCssClass(target);
      if (target.classList.contains(`${LAYER_NAVIGATION.ITEM_UNIVERSE_NO_CHILDREN}`)) {
        onClearTimeOut.bind(null, this.timer);
        this.hideLayerOnLeavePrimaryMenu();
      } else {
        this.prepareLayerAndGetUniverseItems(target);
      }
    });
  }
  prepareLayerAndGetUniverseItems(target) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.openedUniverse !== target) {
        this.prepareLayerElements({
          container: LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS,
          loader: LAYER_NAVIGATION.LOADER_SHOW_CSS
        });
        const urlNavigation = this.getUrl(target, COMMONS.UNIVERSES_AJAX_URL, 'desktop');
        if (!urlNavigation) return;
        this.timer = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
          yield this.getSubUniverseItemFromServer(urlNavigation, target);
        }), 200);
        this.openedUniverse = target;
      }
    });
  }
  getSubUniverseItemFromServer(urlNavigation, currentUniverse) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.getSubItemFromServer(urlNavigation, this.successGetSubUniverseItems.bind(this, currentUniverse));
    });
  }
  updateAccessibilityOnOpenNavigation() {
    this.accessibilityNavigationModule.update(this.triggerNavigationButton, this.triggerNavigationButton, [...[this.triggerNavigationButton], ...this.universeItems], this.triggerNavigationButton);
  }
  updateAccessibilityOnCloseNavigation() {
    this.accessibilityNavigationModule.update(this.triggerNavigationButton, this.triggerNavigationButton, []);
  }
  successGetSubUniverseItems(currentUniverse, response) {
    this.successLayerNavigation(response, this.updateAccessibilityOnOpenNavigation.bind(this));
    setTabIndex(this.triggerNavigationButton, -1);
    const focusableElements = getFocusableElements(this.responseContainer);
    this.accessibilityNavigationModule.onUpdateKeyboardNavigationOnRightArrow(currentUniverse, focusableElements);
    const closeButton = this.responseContainer.querySelector(`.${LAYER_NAVIGATION.CLOSE_BUTTON}`);
    closeButton && this.initCloseButton(closeButton);
  }
  initCloseButton(closeButton) {
    if (!closeButton) return;
    closeButton.removeEventListener('keyup', event => {
      this.onCloseButton(event);
    });
    closeButton.addEventListener('keyup', event => {
      this.onCloseButton(event);
    });
  }
  onCloseButton(event) {
    if (event.key === ACCESSIBILITY.AUTHORIZED_KEY.ENTER || event.code === ACCESSIBILITY.AUTHORIZED_KEY.SPACE) {
      this.triggerNavigationButton.checked = false;
      this.hideLayerOnLeavePrimaryMenu();
      this.triggerNavigationButton.focus();
    }
  }
  initDisableLinks() {
    const linksToDisableList = document.querySelectorAll(`.${LAYER_NAVIGATION.LINK_TO_DISABLE}`);
    linksToDisableList.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
      });
    });
  }
  initToggleSelectedElementCssClass(element) {
    const elementParentList = element.closest(`.${LAYER_NAVIGATION.LAYER_CONTAINERS_SELECTOR}`);
    const siblings = elementParentList.querySelectorAll(`.${LAYER_NAVIGATION.ITEM_UNIVERSE_SELECTOR}`);
    const siblingsList = [...siblings].filter(x => x !== element);
    this.addSelectedElementCssClass(element);
    siblingsList.forEach(link => {
      this.removeSelectedElementCssClass(link);
    });
  }
  addSelectedElementCssClass(element) {
    element === null || element === void 0 ? void 0 : element.setAttribute('aria-expanded', 'true');
    element === null || element === void 0 ? void 0 : element.classList.add(`${LAYER_NAVIGATION.SELECTED_ITEM_UNIVERSE_CSS_CLASS}`);
  }
  removeSelectedElementCssClass(element) {
    element === null || element === void 0 ? void 0 : element.setAttribute('aria-expanded', 'false');
    element.classList.remove(`${LAYER_NAVIGATION.SELECTED_ITEM_UNIVERSE_CSS_CLASS}`);
  }
}