export const ACCESSIBILITY = {
  LOGO_SELECTOR: 'js-logo-lm',
  LAYER_CONTAINER_SELECTOR: 'js-layer-navigation-container',
  AUTHORIZED_KEY: {
    TAB: 'Tab',
    ESCAPE: 'Escape',
    ARROW_DOWN: 'ArrowDown',
    ARROW_UP: 'ArrowUp',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ENTER: 'Enter',
    SPACE: 'Space'
  }
};