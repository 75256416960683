export const LAYER_NAVIGATION = {
  HEADER_ID_SELECTOR: 'component-WebsiteHeaderComponent',
  LAYER_CONTAINERS_SELECTOR: 'js-layer-navigation-container',
  LAYER_LIST_SELECTOR: 'js-layer-navigation-list',
  PRIMARY_SELECTOR: 'js-primary-menu',
  OVERLAY_SELECTOR: 'js-overlay-menu',
  ITEM_UNIVERSE_SELECTOR: 'js-item-universe-menu',
  ITEM_UNIVERSE_NO_CHILDREN: 'js-item-universe-no-children',
  SELECTED_ITEM_UNIVERSE_CSS_CLASS: 'l-layer-navigation__item-button--selected',
  PRIMARY_LIST_ITEM_SELECTOR: 'js-primary-menu-item',
  LOADER_SELECTOR: 'js-layer-loader',
  SUB_MENU_CONTAINER_SELECTOR: 'js-sub-menu-container',
  SUB_MENU_CONTENT_SELECTOR: 'js-sub-menu-content',
  LOADER_SHOW_CSS: 'l-layer-navigation__loader--show',
  LOADER_SHOW_CSS_ON_CLICK: 'l-layer-navigation__loader--show-on-click',
  SEND_TRACKING_EVENT_ELEMENTS: '[data-cdl-custom]',
  SUB_MENU_CONTAINER_SHOW_CSS: 'l-layer-navigation__sub-menu--show',
  SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK: 'l-layer-navigation__sub-menu--show-on-click',
  THEMATIC_LAYER_SELECTOR: 'js-layer-thematic',
  PRIMARY_ITEM_LINK_SELECTOR: 'js-primary-menu-item-link',
  PRIMARY_ITEM_HAS_LAYER_SELECTOR: 'js-primary-menu-item-has-layer',
  SECONDARY_NAVIGATION: 'js-navigation-secondary',
  SECONDARY_NAVIGATION_ITEM: 'js-navigation-secondary-item',
  LINK_TO_DISABLE: 'js-prevent-click',
  BACK_BUTTON: 'js-layer-container-return',
  CLOSE_BUTTON: 'js-layer-container-close',
  TABLET_OVERLAY: 'js-tablet-overlay',
  CACHE: {
    NAME: 'categoryNavigationDesktop',
    VALIDITY: 300000
  }
};