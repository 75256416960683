var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { LAYER_NAVIGATION } from './layer-navigation.constants';
import { LayerPrimaryNavigation } from '../../common/layer-primary-navigation.class';
import { COMMONS } from '../../common/common.constants';
import { addOverLay, getFocusableElements, removeOverLay } from '../../common/common.tools';
import { ACCESSIBILITY } from '../accessibility/accessibility-navigation.constants';
export default class ThematicContentLayer extends LayerPrimaryNavigation {
  constructor(primaryElement, accessibilityNavigationModule) {
    super(LAYER_NAVIGATION.CACHE.NAME, LAYER_NAVIGATION.CACHE.VALIDITY, primaryElement);
    this.accessibilityNavigationModule = accessibilityNavigationModule;
    this.bodyElement = document.querySelector('body');
  }
  init() {
    this.initSubPrimaryItems();
  }
  onDelayMouseEnter(event) {
    this.menuEnterTimer = setTimeout(() => {
      clearTimeout(this.menuLeaveTimer);
      this.onGetSubThematicItems(event.target);
    }, 400);
  }
  onDelayMouseLeave() {
    this.menuLeaveTimer = setTimeout(() => {
      clearTimeout(this.menuEnterTimer);
    }, 400);
  }
  initSubPrimaryItems() {
    var _a;
    if (!this.primaryElement) return;
    this.primaryElement.addEventListener('mouseenter', event => {
      this.onDelayMouseEnter(event);
    });
    this.primaryElement.addEventListener('mouseleave', () => {
      this.onDelayMouseLeave();
    });
    (_a = this.primaryElement.querySelector(`.${COMMONS.TRIGGER_NAVIGATION_BUTTON}`)) === null || _a === void 0 ? void 0 : _a.addEventListener('change', event => __awaiter(this, void 0, void 0, function* () {
      this.triggerElement = event.target;
      if (this.triggerElement.checked) {
        addOverLay();
        this.onGetSubThematicItems(this.primaryElement);
      }
      if (!this.triggerElement.checked) {
        removeOverLay();
        this.onCloseThematicLayer();
      }
    }));
    this.primaryElement.addEventListener('click', () => {
      this.prepareLayerElements({
        container: LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK,
        loader: LAYER_NAVIGATION.LOADER_SHOW_CSS_ON_CLICK
      });
    });
  }
  onGetSubThematicItems(parentElement) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.primaryElement !== parentElement) return;
      const element = parentElement.querySelector(`.${LAYER_NAVIGATION.PRIMARY_ITEM_LINK_SELECTOR}`);
      this.prepareLayerElements({
        container: LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS,
        loader: LAYER_NAVIGATION.LOADER_SHOW_CSS
      });
      const urlNavigation = this.getUrl(element, COMMONS.THEMATIC_AJAX_URL, 'desktop');
      if (!urlNavigation) return;
      yield this.getSubThematicItemFromServer(urlNavigation);
    });
  }
  getSubThematicItemFromServer(urlNavigation) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.getSubItemFromServer(urlNavigation, this.successGetSubThematicItems.bind(this));
    });
  }
  successGetSubThematicItems(response) {
    if (!this.primaryElement) return;
    this.successLayerNavigation(response);
    let focusableElements = this.accessibilityNavigationModule.removeHiddenHTMLElementsFromList(getFocusableElements(this.responseContainer));
    focusableElements = [...[this.triggerElement], ...focusableElements];
    this.accessibilityNavigationModule.update(focusableElements[0], focusableElements[0], focusableElements);
    focusableElements[0] && focusableElements[0].focus();
    this.initOnKeyboardNavigationCloseEvent();
  }
  initOnKeyboardNavigationCloseEvent() {
    document.removeEventListener('keyup', event => {
      this.onKeyboardNavigationCloseEvent(event);
    });
    document.addEventListener('keyup', event => {
      this.onKeyboardNavigationCloseEvent(event);
    });
  }
  onKeyboardNavigationCloseEvent(event) {
    if (event.key === ACCESSIBILITY.AUTHORIZED_KEY.ESCAPE) {
      removeOverLay();
      this.onCloseThematicLayer();
    }
  }
  onCloseThematicLayer() {
    var _a;
    const subNavigationContainer = (_a = this.primaryElement) === null || _a === void 0 ? void 0 : _a.querySelector(`.${LAYER_NAVIGATION.SUB_MENU_CONTAINER_SELECTOR}`);
    subNavigationContainer && subNavigationContainer.classList.remove(LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS, LAYER_NAVIGATION.SUB_MENU_CONTAINER_SHOW_CSS_ON_CLICK);
    if (this.triggerElement) {
      this.triggerElement.checked = false;
      this.triggerElement.focus();
    }
    this.bodyElement.classList.remove(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
  }
}