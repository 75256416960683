import { LAYER_NAVIGATION } from './layer-navigation.constants';
import { addOverLay, removeOverLay, toggleScrollBarOnHoverElement } from '../../common/common.tools';
import AccessibilityNavigationModule from '../accessibility/accessibility-navigation.module';
import UniverseContentLayer from './universe-content-layer.class';
import ThematicContentLayer from './thematic-content-layer.class';
import { COMMONS } from '../../common/common.constants';
import { debounce } from '../../../common/common.utils';
export default class LayerPrimaryMainNavigationModule {
  constructor(mainNavigationContainer) {
    this.primaryMenuContainer = mainNavigationContainer.querySelector(`.${LAYER_NAVIGATION.PRIMARY_SELECTOR}`);
    this.primaryMenuListItems = this.primaryMenuContainer.querySelectorAll(`.${LAYER_NAVIGATION.PRIMARY_LIST_ITEM_SELECTOR}`);
    this.hasLayerListItems = this.primaryMenuContainer.querySelectorAll(`.${LAYER_NAVIGATION.PRIMARY_ITEM_HAS_LAYER_SELECTOR}`);
    this.overlay = mainNavigationContainer.querySelector(`.${LAYER_NAVIGATION.OVERLAY_SELECTOR}`);
    this.accessibilityNavigationModule = new AccessibilityNavigationModule();
    this.accessibilityNavigationModule.init();
  }
  init() {
    toggleScrollBarOnHoverElement(this.primaryMenuContainer);
    this.setDataScrollbarWidthOnBody();
    this.initPrimaryMenuElements();
    this.initActiveOverlayOnHover();
    this.initClickOnTabletOverlay();
    this.initOnWindowResize();
  }
  setDataScrollbarWidthOnBody() {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    document.body.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
  }
  initActiveOverlayOnHover() {
    this.hasLayerListItems.forEach(itemWithLayer => {
      itemWithLayer.addEventListener('mouseleave', removeOverLay);
      itemWithLayer.addEventListener('mouseenter', addOverLay);
    });
  }
  initPrimaryMenuElements() {
    this.primaryMenuListItems.forEach(primaryItem => {
      const layerContainer = primaryItem.querySelector(`.${LAYER_NAVIGATION.LAYER_CONTAINERS_SELECTOR}`);
      if (layerContainer) {
        const contentLayer = this.contentLayerFactory(primaryItem);
        contentLayer.init();
      }
    });
  }
  contentLayerFactory(primaryItem) {
    const contentType = primaryItem.getAttribute(COMMONS.LAYERS_TYPE_SELECTOR);
    const contentTypeLayerInstance = contentType === COMMONS.LAYERS_TYPE[0] ? new UniverseContentLayer(primaryItem, this.accessibilityNavigationModule) : new ThematicContentLayer(primaryItem, this.accessibilityNavigationModule);
    return contentTypeLayerInstance;
  }
  initClickOnTabletOverlay() {
    this.primaryMenuContainer.querySelectorAll(`.${LAYER_NAVIGATION.TABLET_OVERLAY}`).forEach(elt => {
      elt.addEventListener('click', event => {
        var _a;
        ((_a = event.target.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector(`.${COMMONS.TRIGGER_NAVIGATION_BUTTON}`)).checked = false;
      });
    });
  }
  initOnWindowResize() {
    this.accessibilityNavigationModule.adjustTabindexForAccessibility();
    window.addEventListener('resize', debounce(this.accessibilityNavigationModule.adjustTabindexForAccessibility, 500));
  }
}