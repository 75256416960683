import { getTmsInstance } from 'integration-web-core--socle/js/assets/commons/_tms';
export const sendTrackingEvent = (element, mainLevelLabel) => {
  const {
    cdlCustom
  } = element.dataset;
  if (cdlCustom) {
    const parsedDataCustomCdlArray = JSON.parse(cdlCustom);
    parsedDataCustomCdlArray.forEach(parsedDataCustomCdl => {
      const trackingEventType = parsedDataCustomCdl[0];
      const trackingEventObject = parsedDataCustomCdl[1];
      if (mainLevelLabel) {
        trackingEventObject.event_data.menu_lvl0 = mainLevelLabel;
      }
      getTmsInstance().pushEvent(element, trackingEventType, trackingEventObject);
    });
  }
};
export const initShowmore = (componentSelector, showMoreTriggerSelector) => {
  const component = document.querySelector(`.${componentSelector}`);
  if (!component) return;
  const showmoreTrigger = component.querySelector(`.${showMoreTriggerSelector}`);
  showmoreTrigger === null || showmoreTrigger === void 0 ? void 0 : showmoreTrigger.addEventListener('change', () => {
    if (showmoreTrigger.checked) {
      component.classList.add('--show-all');
    } else {
      component.classList.remove('--show-all');
    }
  });
};
export const debounce = (func, time) => {
  let timer;
  return event => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, time, event);
  };
};
export const isElementVisibleInContainer = (container, element) => {
  const containerBounds = container.getBoundingClientRect();
  const elemBounds = element.getBoundingClientRect();
  return elemBounds.top >= containerBounds.top && elemBounds.bottom <= containerBounds.bottom && elemBounds.left >= containerBounds.left && elemBounds.right <= containerBounds.right;
};