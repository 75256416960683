import LayerPrimaryMainNavigationModule from './layer/layer-primary-main-navigation.module';
class MainNavigationDesktop extends lm.Composant {
  constructor(id) {
    super(id);
  }
  init() {
    const layerNavigationModule = new LayerPrimaryMainNavigationModule(this.target);
    layerNavigationModule.init();
  }
}
lm.DOMReady(function () {
  const mainNavigationDesktop = new MainNavigationDesktop('main-navigation');
  mainNavigationDesktop.init();
});