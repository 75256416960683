import { sendTrackingEvent } from '../../common/common.utils';
import { COMMONS } from './common.constants';
export const setTabIndex = (element, tabIndexValue) => {
  if (element) {
    element.tabIndex = tabIndexValue;
  }
};
export const setNoScrollBody = force => {
  const documentBody = document.querySelector('body');
  documentBody.classList.toggle(`${COMMONS.NO_SCROLL_CSS_CLASS}`, force);
};
export const toggleScrollBarOnHoverElement = element => {
  const bodyElement = document.querySelector('body');
  element.addEventListener('mouseover', () => {
    bodyElement.classList.add(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
  });
  element.addEventListener('mouseleave', () => {
    bodyElement.classList.remove(`${COMMONS.NO_SCROLL_CSS_CLASS}`);
  });
};
export const getOffsetHeaderValues = () => {
  const headerComponent = document.getElementById(COMMONS.HEADER_ID_SELECTOR);
  return {
    height: headerComponent.offsetHeight,
    top: headerComponent.offsetTop
  };
};
export const onError = error => {
  throw new Error(`Error : ${JSON.stringify(error)}`);
};
export const isStringEmpty = value => {
  return value === null || value === undefined || value.trim() === '';
};
export const onClearTimeOut = timer => {
  clearTimeout(timer);
};
export const getFocusableElements = element => {
  const focusableElementsList = element.querySelectorAll(COMMONS.FOCUSABLE_ELEMENTS);
  return [...focusableElementsList];
};
export const isElementHaveStyleClass = (element, className) => {
  return element.classList.contains(className);
};
export const uncheckAllCheckboxInputsInContainer = (container, checkboxSelector) => {
  const triggerSecondLevelInputs = container.querySelectorAll(`.${checkboxSelector}`);
  triggerSecondLevelInputs.forEach(secondLevelInput => {
    secondLevelInput.checked = false;
  });
};
export const initSubLevelTracking = (containerToTrack, mainLevelLabel) => {
  if (!containerToTrack) return;
  const elementsToTrack = containerToTrack.querySelectorAll(`${COMMONS.SEND_TRACKING_EVENT_ELEMENTS}`);
  elementsToTrack.forEach(subUniverseLink => {
    subUniverseLink.addEventListener('click', event => {
      const target = event.target;
      sendTrackingEvent(target, mainLevelLabel);
    });
  });
};
const header = document.querySelector(`#${COMMONS.NAVIGATION_ID_SELECTOR}`);
export const addOverLay = () => {
  header === null || header === void 0 ? void 0 : header.classList.add(`${COMMONS.OVERLAY_ACTIVE_HEADER}`);
};
export const removeOverLay = () => {
  header === null || header === void 0 ? void 0 : header.classList.remove(`${COMMONS.OVERLAY_ACTIVE_HEADER}`);
};
export const toggleOverLay = () => {
  header === null || header === void 0 ? void 0 : header.classList.toggle(`${COMMONS.OVERLAY_ACTIVE_HEADER}`);
};