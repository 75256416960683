var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { FETCH_OPTION } from './common.constants';
export default class HttpModule {
  static get(url) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const _fetchOptions = Object.assign({
          method: 'GET'
        }, FETCH_OPTION);
        const responseFetch = yield fetch(url, _fetchOptions);
        if (!responseFetch.ok || responseFetch.status !== 200) {
          throw Error(responseFetch.statusText);
        }
        return responseFetch;
      } catch (error) {
        throw Error(error);
      }
    });
  }
}