export const COMMONS = {
  NO_SCROLL_CSS_CLASS: 'body-no-scroll',
  UNIVERSES_AJAX_URL: '/application/ajax/mainnavigation',
  THEMATIC_AJAX_URL: '/application/ajax/thematicnavigation',
  HEADER_ID_SELECTOR: 'component-WebsiteHeaderComponent',
  NAVIGATION_ID_SELECTOR: 'component-main-navigation',
  SEND_TRACKING_EVENT_ELEMENTS: '[data-cdl-custom]',
  LAYERS_TYPE: ['universes', 'thematic'],
  LAYERS_TYPE_SELECTOR: 'data-layer-type',
  TRIGGER_NAVIGATION_BUTTON: 'js-trigger-main-navigation',
  UNIVERSES_LIST: 'js-universes-list',
  FOCUSABLE_ELEMENTS: 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
  OVERLAY_SELECTOR: 'js-overlay-menu',
  OVERLAY_ACTIVE_HEADER: 'overlay-active'
};
export const FETCH_OPTION = {
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'text/html'
  },
  cache: 'no-cache'
};